class ChronService {
  private readonly chrons = new Map();
  addTimeout(name: string, callback: () => void, timeout: number) {
    this.chrons.set(
      name,
      setTimeout(() => {
        callback();
        this.removeChron(name);
      }, timeout)
    );
  }

  removeChron(name: string) {
    clearTimeout(this.chrons.get(name));
    this.chrons.delete(name);
  }

  getChron(name: string) {
    return this.chrons.get(name);
  }

  getEfeReportsChronJobs() {
    return [...this.chrons]
      .filter(([chronJobName]) => chronJobName.includes('efe_report'))
      .map(([chronJobName]) => chronJobName);
  }
}

export default ChronService;
