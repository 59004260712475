import { createSelector } from '@reduxjs/toolkit';

import { selectCurrenciesList } from './currencies';
import { selectJurisdictions, selectJurisdictionsList, selectJurisdictionsWithRatesById } from './jurisdictions';

import { alphaSortById } from '../components/UploadReview/components/AccountRangesTab/utils';
import { UPLOAD_REVIEW_TABS } from '../constants';
import {
  AccountRange,
  AccountShape,
  JurisdictionByEntityId,
  CurrencyByEntityId,
  SubJurisdictionsByEntityId,
  TabsValidations,
  SubJurisdiction,
  Category
} from '../models';
import { RootState } from '../redux';

export const selectUploadReview = (state: RootState) => state.uploadReview;

export const selectUploadReviewEntities = (state: RootState) => state.uploadReview.entities;
export const selectUploadReviewRanges = (state: RootState) => state.uploadReview.ranges;
export const selectUploadReviewAccounts = (state: RootState) => state.uploadReview.accounts ?? [];
export const selectUploadReviewCategories = (state: RootState) => state.uploadReview.categories ?? [];

export const selectCurrencyByEntityId = createSelector(
  selectUploadReviewEntities,
  selectCurrenciesList,
  (entities, currencies) => {
    const currencyByEntityId: CurrencyByEntityId = {};
    if (currencies.length === 0) {
      return currencyByEntityId;
    }

    entities.forEach((entity) => {
      if (entity.currencyId !== undefined) {
        // this cannot be undefined, and if it is we should error
        currencyByEntityId[entity.id] = currencies.find((currency) => currency.currencyId === entity.currencyId)!;
      }
    });
    return currencyByEntityId;
  }
);

export const selectJurisdictionByEntityId = createSelector(
  selectUploadReviewEntities,
  selectJurisdictionsList,
  (entities, jurisdictions) => {
    const jurisdictionByEntityId: JurisdictionByEntityId = {};
    if (jurisdictions.length === 0) {
      return jurisdictionByEntityId;
    }

    entities.forEach((entity) => {
      if (entity.jurisdictionId !== undefined) {
        // this cannot be undefined, and if it is we should error
        jurisdictionByEntityId[entity.id] = jurisdictions.find(
          (jurisdiction) => jurisdiction.jurisdictionId === entity.jurisdictionId
        )!;
      }
    });
    return jurisdictionByEntityId;
  }
);

export const selectSubJurisdictionsByEntityId = createSelector(
  selectUploadReviewEntities,
  selectJurisdictions,
  selectJurisdictionsWithRatesById,
  (entities, { jurisdictions }, jurisdictionById) => {
    const subJurisdictionsByEntityId: SubJurisdictionsByEntityId = {};
    if (jurisdictions.length === 0) {
      return subJurisdictionsByEntityId;
    }

    entities.forEach((entity) => {
      if (entity.jurisdictionId !== undefined && entity.subJurisdictionIds !== undefined) {
        const jurisdiction = jurisdictionById[entity.jurisdictionId];
        // this cannot be undefined, and if it is we should error
        subJurisdictionsByEntityId[entity.id] = entity.subJurisdictionIds.map((entitySubJurisdictionId) => {
          return jurisdiction.subJurisdictions.find(
            (subJurisdiction: SubJurisdiction) => subJurisdiction.subJurisdictionId === entitySubJurisdictionId
          )!;
        });
      }
    });

    return subJurisdictionsByEntityId;
  }
);

export const hasAllAccountsAssignedToARange = (
  accounts: AccountShape[],
  ranges: AccountRange[],
  prov4419RequirementsUploadManagerStep: boolean
) => {
  const rangesBordersIndexPairs = ranges.map(({ range }) => {
    const rangeStartIndex = accounts.findIndex(({ id }) => id === range[0]);
    const rangeEndIndex = accounts.findIndex(({ id }) => id === range[1]);

    return [rangeStartIndex, rangeEndIndex];
  });

  const assignedAccounts = rangesBordersIndexPairs.flatMap(([startIndex, endIndex]) =>
    accounts.slice(startIndex, endIndex + 1)
  );

  if (!prov4419RequirementsUploadManagerStep) return accounts.length === assignedAccounts.length;

  const profitAndLossRangeIndex = ranges.findIndex((range) => range.id === 'profitAndLoss');
  const isProfitAndLossRangeSelected =
    ranges[profitAndLossRangeIndex].range.length === 2 &&
    ranges[profitAndLossRangeIndex].range.every((account) => account.trim().length > 0);

  return accounts.length === assignedAccounts.length && isProfitAndLossRangeSelected;
};

export const hasIncomeTaxBenefitSelected = (categories: Category[], prov4419RequirementsUploadManagerStep: boolean) => {
  if (!prov4419RequirementsUploadManagerStep) return true;

  const incomeTaxBenefitIndex = categories.findIndex((category) => category.id === 'income-tax');
  return categories[incomeTaxBenefitIndex].accountIds.length > 0;
};

export const tabsValidations: TabsValidations = {
  currencies: (entities) => entities.every((entity) => entity.currencyId !== undefined),
  jurisdictions: (entities) => entities.every((entity) => entity.jurisdictionId !== undefined),
  states: (entities, jurisdictionByEntityId) =>
    entities
      .filter((entity) => jurisdictionByEntityId[entity.id]?.subJurisdictions.length > 0)
      .every((entity) => entity.subJurisdictionIds && entity.subJurisdictionIds?.length > 0),
  // eslint-disable-next-line max-params
  ranges: (_entities, _jurisdictionByEntityId, ranges, accounts, _categories, prov4419RequirementsUploadManagerStep) =>
    hasAllAccountsAssignedToARange(accounts, ranges, prov4419RequirementsUploadManagerStep),
  'tax-sensitive': (
    _entities,
    _jurisdictionByEntityId,
    _ranges,
    _accounts,
    categories,
    prov4419RequirementsUploadManagerStep
    // eslint-disable-next-line max-params
  ) => hasIncomeTaxBenefitSelected(categories, prov4419RequirementsUploadManagerStep)
};

export const selectUploadReviewAccountsSorted = createSelector(selectUploadReviewAccounts, (accounts) =>
  accounts.slice().sort(alphaSortById)
);

export const selectEnabledTabsList = (prov4419RequirementsUploadManagerStep: boolean) =>
  createSelector(
    selectUploadReviewEntities,
    selectJurisdictionByEntityId,
    selectUploadReviewRanges,
    selectUploadReviewAccountsSorted,
    selectUploadReviewCategories,
    // eslint-disable-next-line max-params
    (entities, jurisdictionByEntityId, ranges, accounts, categories) => {
      const enabledList = Array.from<boolean>({ length: UPLOAD_REVIEW_TABS.length }).fill(false);
      enabledList[0] = true;
      for (let i = 0; i < UPLOAD_REVIEW_TABS.length - 1; i++) {
        // We need to remove the optional chaining once we have all the validators
        const isValid = tabsValidations[UPLOAD_REVIEW_TABS[i].key]?.(
          entities,
          jurisdictionByEntityId,
          ranges,
          accounts,
          categories,
          prov4419RequirementsUploadManagerStep
        );
        if (isValid) {
          enabledList[i + 1] = true;
        } else {
          break;
        }
      }

      return enabledList;
    }
  );

export const selectUploadIsLoading = (state: RootState) => state.uploadReview.isInitialUploadLoading;
export const selectUploadHasLoaded = (state: RootState) => state.uploadReview.hasInitialUploadLoaded;
