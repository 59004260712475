import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Apps from '@material-ui/icons/Apps';
import { Skeleton } from '@material-ui/lab';
import { ArrowNavigationTabsBar } from '@xbs/xbs-common-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch } from 'redux';

import {
  AccountRangesTab,
  ConfirmTab,
  CurrenciesTab,
  JurisdictionsTab,
  StatesTab,
  TaxSensitiveTab
} from './components';
import { convertAccountsToUSD } from './utils';

import { UPLOAD_REVIEW_TABS, UPLOAD_REVIEW_TABS_ERRORS, UPLOAD_REVIEW_TABS_ERRORS_4419_FLAG } from '../../constants';
import { useContainers, useCurrencies, useEntities, useJurisdictions, useUpload } from '../../hooks';
import { AccountRange } from '../../models';
import { enqueueNotification } from '../../redux/notifications';
import { uploadReviewResetData, uploadReviewSetTab } from '../../redux/uploadReview';
import { selectEnabledTabsList } from '../../selectors';
import { formatDateLong } from '../../utils';

const useStyles = makeStyles((theme) => {
  const sepColor = theme.palette.divider;
  return {
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: sepColor
    },
    appBar: {
      padding: theme.spacing(1, 1.5),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.secondary,
      borderBottom: `2px solid ${sepColor}`,

      '& .MuiToolbar-regular': {
        minHeight: 0
      },
      '& h1': {
        padding: theme.spacing(0, 2, 0, 1)
      },
      '& h2': {
        padding: theme.spacing(0, 2)
      }
    },
    appTitle: {
      display: 'flex',
      flexGrow: 1,
      color: theme.palette.text.primary,

      '& > div': {
        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily
      }
    },
    tabContainer: {
      flexGrow: 1,
      overflow: 'auto',
      padding: theme.spacing(1.5)
    }
  };
});

const onTabClick = (
  tab: string,
  enabledTabs: boolean[],
  dispatch: Dispatch,
  prov4419RequirementsUploadManagerStep: boolean
) => {
  const tabInd = UPLOAD_REVIEW_TABS.findIndex((tabObj) => tabObj.key === tab);
  if (enabledTabs[tabInd]) {
    dispatch(uploadReviewSetTab(tab));
  } else {
    const lastEnabledTab = enabledTabs.lastIndexOf(true);
    dispatch(
      enqueueNotification({
        message: prov4419RequirementsUploadManagerStep
          ? UPLOAD_REVIEW_TABS_ERRORS_4419_FLAG[lastEnabledTab]
          : UPLOAD_REVIEW_TABS_ERRORS[lastEnabledTab],
        options: {
          variant: 'warning'
        }
      })
    );
  }
};

const rangeIdsByCategoryId: Record<string, string[]> = {
  'permanent-adjustments': ['profitAndLoss'],
  'temporary-adjustments': ['assets', 'liabilities', 'profitAndLoss', 'shareholdersEquity'],
  'income-tax': ['profitAndLoss']
};

const UploadReview = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currencies, containerFxRatesByCurrencyId } = useCurrencies();
  const { entitiesWithRatesByEntityNumber } = useEntities();
  const { jurisdictions } = useJurisdictions();
  const { currentContainer } = useContainers();
  const { prov4419RequirementsUploadManagerStep } = useFlags();
  const enabledTabs = useSelector(selectEnabledTabsList(prov4419RequirementsUploadManagerStep));
  const {
    entities,
    accounts,
    tab,
    ranges,
    currencyByEntityId,
    jurisdictionByEntityId,
    subJurisdictionsByEntityId,
    categories,
    metaData: { fileName, updatedOn },
    isUploadReviewCompleted,
    isLoading,
    error
  } = useUpload();

  const uploadReviewTabs = useMemo(() => {
    return UPLOAD_REVIEW_TABS.map((inputTab) => {
      return {
        ...inputTab,
        onTabClick: () => {
          onTabClick(inputTab.key, enabledTabs, dispatch, prov4419RequirementsUploadManagerStep);
        }
      };
    });
  }, [dispatch, enabledTabs, prov4419RequirementsUploadManagerStep]);

  const convertedAccounts = useMemo(
    () =>
      convertAccountsToUSD(accounts, containerFxRatesByCurrencyId, entitiesWithRatesByEntityNumber, currencyByEntityId),
    [accounts, containerFxRatesByCurrencyId, currencyByEntityId, entitiesWithRatesByEntityNumber]
  );

  useEffect(() => {
    if (error) {
      history.push('/');
    }
  }, [error, history]);

  useEffect(() => {
    return () => {
      dispatch(uploadReviewResetData());
    };
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Toolbar disableGutters>
          <Apps />
          <Typography variant="h2" component="h1">
            {t('Upload Manager')}
          </Typography>
          <Divider flexItem orientation="vertical" />
          <Typography variant="h3" component="h2" className={classes.appTitle}>
            <Box flexGrow={1}>{fileName ? fileName : <Skeleton width={150} />}</Box>
            <Box>
              {updatedOn ? (
                t('Uploaded _', {
                  date: formatDateLong(updatedOn)
                })
              ) : (
                <Skeleton width={150} />
              )}
            </Box>
          </Typography>
          <Button variant="outlined" color="primary" component={Link} to="/">
            {t('Close')}
          </Button>
        </Toolbar>
      </AppBar>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ onTabClick: () => any; key: string; label:... Remove this comment to see the full error message */}
      <ArrowNavigationTabsBar tabs={uploadReviewTabs} selectedTab={tab} />
      <Box className={classes.tabContainer}>
        {isLoading || !currentContainer ? (
          <CircularProgress />
        ) : tab === 'tax-sensitive' ? (
          <TaxSensitiveTab
            entities={entities}
            accounts={accounts}
            ranges={ranges}
            categorySpecs={categories}
            rangeIdsByCategoryId={rangeIdsByCategoryId}
          />
        ) : tab === 'ranges' ? (
          <AccountRangesTab accounts={accounts} entities={entities} rangeSpecs={ranges} />
        ) : tab === 'states' ? (
          <StatesTab
            entities={entities}
            jurisdictions={jurisdictions}
            jurisdictionByEntityId={jurisdictionByEntityId}
            subJurisdictionsByEntityId={subJurisdictionsByEntityId}
          />
        ) : tab === 'jurisdictions' ? (
          <JurisdictionsTab
            entities={entities}
            jurisdictions={jurisdictions}
            jurisdictionByEntityId={jurisdictionByEntityId}
          />
        ) : tab === 'confirm' ? (
          <ConfirmTab
            isUploadReviewCompleted={isUploadReviewCompleted}
            entities={entities}
            accounts={convertedAccounts}
            container={currentContainer}
            categories={categories}
            profitAndLossRange={
              ranges.find(({ id }: { id: AccountRange['id'] }) => id === 'profitAndLoss')?.range ?? []
            }
            isImportDisabled={isUploadReviewCompleted ?? isLoading}
          />
        ) : (
          <CurrenciesTab entities={entities} currencies={currencies} currencyByEntityId={currencyByEntityId} />
        )}
      </Box>
    </Box>
  );
};

export default UploadReview;
