import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import { saveAs } from 'file-saver';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { REPORT_STATUS, WorldGroupUuid, defaultGroupNames } from '../../../constants';
import { Container, CustomGroup } from '../../../models';
import { enqueueNotification } from '../../../redux/notifications';
import { updateEfeReportsStatus } from '../../../redux/reports/reports.actions';
import { selectEntitiesMapById } from '../../../selectors';
import { selectCustomGroups } from '../../../selectors/customGroups';
import { selectEfeReportsMeta } from '../../../selectors/reports';
import ChronService from '../../../services/chron';
import HTTPService from '../../../services/http';
import b64toBlob from '../../../utils/b64-to-blob';
import LoadingSpinner from '../../LoadingSpinner';
import LoadingSpinnerWithLabel from '../../LoadingSpinner/LoadingSpinnerWithLabel';

interface Props {
  spreadsheetReportsDemo: boolean;
  reportType: string;
  reportSourceId: string;
  currencyISO: string;
  container?: Container;
}

interface pollQueryEfeReportProps {
  reportType: string;
  reportSourceId: string;
  currencyISO: string;
  requestId: string;
  container?: Container;
  retriesLeft: number;
  entities: any;
  customGroups: CustomGroup[];
  dispatch: any;
  t: any;
}

const POLLING_STATUSES = {
  queued: 'QUEUED',
  inProgress: 'IN-PROGRESS',
  success: 'SUCCESS',
  failure: 'FAILURE'
};

const DownloadReportsButton = ({
  spreadsheetReportsDemo,
  reportType,
  reportSourceId,
  currencyISO,
  container
}: Props) => {
  const { t } = useTranslation();
  const { prov4353EfeReportsProgressTracking } = useFlags();
  const entities = useSelector(selectEntitiesMapById);
  const { customGroups } = useSelector(selectCustomGroups);
  const efeReportsMeta = useSelector(selectEfeReportsMeta);
  const dispatch = useDispatch();
  let progress = 0;
  const reportTypeKey = reportType === 'single-entity' ? 'entity' : 'group';
  const reportMeta = efeReportsMeta?.[reportTypeKey]?.[reportSourceId];

  const pollQueryEfeReport = async ({
    reportType,
    reportSourceId,
    currencyISO,
    requestId,
    container,
    entities,
    customGroups,
    dispatch,
    t,
    retriesLeft = 10
  }: pollQueryEfeReportProps) => {
    const handleError = () => {
      dispatch(
        updateEfeReportsStatus({
          reportType,
          reportSourceId,
          status: REPORT_STATUS.failed,
          progression: []
        })
      );
      return dispatch(
        enqueueNotification({
          message: t(
            'The report was unable to download.Please try again or reach\nout to provisionsupport@exactera.com for further assistance.'
          ),
          options: {
            variant: 'error',
            style: { whiteSpace: 'pre-line' }
          }
        })
      );
    };

    if (retriesLeft > 0) {
      ChronService.addTimeout(
        `efe_report_container_${container?.containerId ?? ''}_${reportSourceId}_job_id`,
        async () => {
          let isReportReady = false;
          let response: null | { status: number; url: string; progressionRecords: any[] } = null;
          try {
            response = await HTTPService.request({
              apiUrlKey: 'excelReports',
              method: 'get',
              relativePath: `/v1/workbook/${reportType}/${reportSourceId}?requestId=${requestId}`
            });

            isReportReady = Boolean(response?.status !== 404);
          } catch {}

          if (isReportReady) {
            dispatch(
              updateEfeReportsStatus({ reportType, reportSourceId, progression: [], status: REPORT_STATUS.ready })
            );
            const report = await HTTPService.simpleRequest({
              method: 'get',
              url: String(response?.url)
            });

            const reportSourceName =
              entities[reportSourceId]?.name ??
              customGroups.find((customGroup) => customGroup.groupId === reportSourceId)?.name ??
              defaultGroupNames[reportSourceId] ??
              '';

            saveAs(
              b64toBlob(report),
              `${String(container?.taxYear)} - ${String(container?.containerName)} - Reports (${String(
                reportSourceName
              )}).xlsx`
            );
          } else {
            const failedStep = (response?.progressionRecords ?? []).find(
              (step) => step.status === POLLING_STATUSES.failure
            );
            if (failedStep) {
              return handleError();
            }

            dispatch(
              updateEfeReportsStatus({
                reportType,
                reportSourceId,
                status: REPORT_STATUS.inProgress,
                progression: response?.progressionRecords ?? []
              })
            );

            void pollQueryEfeReport({
              entities,
              container,
              requestId,
              reportType,
              currencyISO,
              customGroups,
              reportSourceId,
              dispatch,
              t,
              retriesLeft: retriesLeft - 1
            });
          }
        },
        prov4353EfeReportsProgressTracking ? 500 : 5000
      );
    } else {
      return handleError();
    }
  };

  if (reportMeta?.status === REPORT_STATUS.inProgress) {
    const successfullSteps = reportMeta.progression.filter(
      (progressionStep) => progressionStep.status === POLLING_STATUSES.success
    );
    const inProgressStep = reportMeta.progression.find(
      (progressionStep) => progressionStep.status === POLLING_STATUSES.inProgress
    );
    const numberOfSteps = reportMeta.progression.length;
    const numberOfSuccessfullSteps = successfullSteps.length;

    const title =
      numberOfSteps === numberOfSuccessfullSteps || !inProgressStep?.step
        ? 'Downloading ...'
        : `Building '${String(inProgressStep?.step)}' sheet.`;

    progress =
      numberOfSteps === 0 || numberOfSuccessfullSteps === 0
        ? 0
        : Math.round((numberOfSuccessfullSteps / numberOfSteps) * 100);

    return prov4353EfeReportsProgressTracking ? (
      <LoadingSpinnerWithLabel progress={progress} title={title} />
    ) : (
      <LoadingSpinner />
    );
  }

  return (
    <Button
      variant="outlined"
      onClick={async () => {
        if (spreadsheetReportsDemo) {
          const { data }: any = await HTTPService.request({
            apiUrlKey: 'reportsApiUrl',
            method: 'get',
            relativePath: `/v1/reports/single-entity/uuid/summary/2020-12-12/USD?targetContainerId=${String(
              WorldGroupUuid
            )}`
          });

          const report: any = await HTTPService.simpleRequest({
            method: 'get',
            url: data.url,
            responseType: 'blob'
          });

          saveAs(report, `2022 Tax Provision - Interco Inc Reporting Package.xlsx`);
          return;
        }

        dispatch(
          updateEfeReportsStatus({ reportType, reportSourceId, status: REPORT_STATUS.inProgress, progression: [] })
        );

        const response: any = await HTTPService.request({
          apiUrlKey: 'excelReports',
          method: 'post',
          relativePath: `/v1/workbook/${reportType}/${reportSourceId}`
        });

        void pollQueryEfeReport({
          entities,
          container,
          reportType,
          currencyISO,
          customGroups,
          reportSourceId,
          dispatch,
          t,
          retriesLeft: prov4353EfeReportsProgressTracking ? 500 : 50,
          requestId: response.requestId
        });
      }}
    >
      {t('Download Reports')}
    </Button>
  );
};

export default DownloadReportsButton;
