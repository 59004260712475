import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ContainersDropdownContainer from './components/ContainersDropdownContainer';
import PeriodBeginningItem from './components/PeriodBeginningItem';
import PeriodEndingItem from './components/PeriodEndingItem';

import { ReactComponent as UploadManagerIcon } from '../../assets/img/UploadManager.svg';
import { Container } from '../../models';
import { resetReportsState } from '../../redux/reports';
import { selectModelsMeta } from '../../selectors/reports';
import ChronService from '../../services/chron/';
import { isSomeModelUpdating } from '../Reports/utils';
import UpdatingBadge from '../UpdatingBadge';
import { hideTBUploadManagerButtonForRoles } from '../UserRoleStylesProvider/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '76px',
    padding: theme.spacing(1, 1.5),
    '& .MuiInput-underline': {
      '&::after, &::before, &:hover::after, &:hover::before': {
        borderBottom: 'none'
      }
    },

    '& label': {
      fontWeight: 'semi-bold'
    },
    '& .MuiTypography-caption': {
      fontSize: '14px'
    }
  },
  divider: {
    margin: theme.spacing(0.5, 0),
    backgroundColor: theme.palette.primary.dark
  },
  rightContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  uploadManagerBtn: {
    '& .label': {
      marginLeft: '0.3rem'
    }
  }
}));

export type ActionBarProps = {
  containers: Container[];
  currentContainer: Container;
  onContainerChanged: (containerId: string) => void;
  isLoading: boolean;
};

const ActionBar = ({ containers, currentContainer, onContainerChanged, isLoading }: ActionBarProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const history = useHistory();
  const {
    uploadManager: uploadManagerFlag,
    prov3137ReportsStatusRework: isReportsStatusReworkOn,
    prov4280OnlyEfeReportsView
  } = useFlags();
  const modelsMeta = useSelector(selectModelsMeta);
  const areModelsUpdating = useMemo(() => {
    const isSomeEntityModelUpdating = isSomeModelUpdating(Object.values(modelsMeta.entity));
    const isSomeGroupModelUpdating = isSomeModelUpdating(Object.values(modelsMeta.group));
    return isSomeEntityModelUpdating || isSomeGroupModelUpdating;
  }, [modelsMeta]);
  const dispatch = useDispatch();

  const handleContainerSelected = (name: string, taxYear: string) => {
    const newContainer = containers.find(
      (container) => container.containerName === name && `${container.taxYear}` === taxYear
    )!;

    const efeReportsChronJobs = ChronService.getEfeReportsChronJobs();

    if (efeReportsChronJobs.length > 0) {
      dispatch(resetReportsState());
      efeReportsChronJobs.forEach((efeReportsChronJob) => {
        ChronService.removeChron(efeReportsChronJob);
      });
    }

    onContainerChanged(newContainer.containerId);
  };

  const handleUploadManagerOpen = () => {
    history.push('/upload-manager');
  };

  const shouldShowUpdatingBadge: boolean = isReportsStatusReworkOn && areModelsUpdating && !prov4280OnlyEfeReportsView;

  return (
    <Grid container className={classes.root} alignItems="center">
      <ContainersDropdownContainer
        currentContainer={currentContainer}
        containers={containers}
        isDropdownOpen={isDropdownOpen}
        isLoading={isLoading}
        setIsDropdownOpen={(val) => {
          setIsDropdownOpen(val);
        }}
        onContainerSelected={handleContainerSelected}
      />
      <PeriodBeginningItem currentContainer={currentContainer} t={t} />
      <PeriodEndingItem currentContainer={currentContainer} t={t} />
      <Box className={classes.rightContainer}>
        {isReportsStatusReworkOn && shouldShowUpdatingBadge && <UpdatingBadge variant="warning" />}
        {uploadManagerFlag && (
          <Button
            data-roles-hide-element={hideTBUploadManagerButtonForRoles.join(' ')}
            className={classes.uploadManagerBtn}
            onClick={handleUploadManagerOpen}
          >
            <UploadManagerIcon className="icon" />
            <Typography variant="body2" className="label">
              {t('upload-manager')}
            </Typography>
          </Button>
        )}
      </Box>
    </Grid>
  );
};

export default ActionBar;
